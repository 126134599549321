import React from 'react';
import dynamic from 'next/dynamic';
import { NextImage } from '../elements';
import {
  BannerWrapper,
  BanerContent,
  BannerDesktop,
  BannerMobile,
  IconWrapper
} from './styles';
import EditorJSContent from '../editor-js-content';

const Link = dynamic(() => import('../elements/link/link'));
export const Banner = ({
  icon,
  description,
  descriptionMobile,
  link,
  linkMobile,
}) => {

  return (
    <BannerWrapper>
      <BanerContent>
        <BannerDesktop>
          {icon && (
            <IconWrapper>
              <NextImage media={icon} />
            </IconWrapper>
          )}
          {description && <EditorJSContent content={description} />}
          {link && <Link {...link}>{link.text}</Link>}
        </BannerDesktop>
        <BannerMobile>
          {descriptionMobile && <EditorJSContent content={descriptionMobile} />}
          {linkMobile && <Link {...linkMobile}>{linkMobile.text}</Link>}
        </BannerMobile>
      </BanerContent>
    </BannerWrapper>
  )
};

export default Banner;