import styled from "styled-components";
import tw from "tailwind-styled-components";

export const BannerWrapper = styled.div`
  width: 100%;
  padding: 8px 10px;
  margin: 0 auto;
  background-color: #CCE0DF;
  position: relative;
  top: -14px;
  
  p{
    margin: 0;
  }
  a{
    font-weight: bold;
  }
  @media screen and (min-width: 1128px) {
    position: absolute;
    top: 81px;
  }
`;

export const BanerContent = tw.div`
  flex
  flex-row
  gap-2
  align-center
  justify-center
  text-base
  text-center
  
`;
export const BannerDesktop = tw.div`
  flex
  flex-row
  gap-2
  hidden
  align-center
  items-center
  desktop:flex
`;

export const BannerMobile = tw.div`
  flex
  flex-col
  gap-2
  desktop:hidden
`;

export const IconWrapper = styled.div`
img{
    aspect-ratio: 1/1;
    width: 16px;
    height: auto;
  }
`;